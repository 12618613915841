/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  ListItemText,
  IconButton,
  TablePagination,
  FormControlLabel,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import { Search, Add, Delete } from '@mui/icons-material'
import {
  getAllDataPlatformsCustomFunctions,
  getAllDbTypes,
  getAllFileTypes,
  getAllFunctionParameterTypes,
  getAllSubTypes,
  getAllTypes,
} from 'api/services/api-services'
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers'
import { environment } from 'environments/environment'
import {
  getProfileInfo,
  getToken,
  removeProfileInfo,
  removeToken,
} from 'api/apiUtility'
import { ApiServices } from 'api/services'
import { CUSTOM_COLORS } from 'theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import SnackbarComponent from 'components/atoms/snackbar-component'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Snackbar_Props, Variant } from 'types/props-types'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { userInfo } from 'os'
import DropdownComponent from 'components/atoms/dropdown'

export type snackbarConfigTypes = {
  isOpen: boolean
  type: Variant
  autoHideDuration: number
  message: string
}

// Define TypeScript types for fetched data
interface Type {
  id: string
  name: string
}

interface SubType {
  id: string
  name: string
}

interface DbType {
  id: string
  name: string
}

interface ParameterType {
  id: string
  name: string
}
interface FileType {
  id: string
  name: string
}
interface Param {
  key: string
  value: string
  type: string
  isRequired: boolean
  label: string
}

interface TransformationFunction {
  id: string
  transformation_function: string
  transformation_function_description: string
  transformation_function_type: string
  transformation_function_sub_type: string
  db_type: string[]
  data_platforms_type: string[]
  file_type: string
  input_columns: string[]
  output_column: string
  params: Param[]
  return_type: string
  is_higher_order_function: boolean
  active: boolean
  visibility: string
  file_name: string
}

interface FormData {
  id: string | null
  functionType: string
  packageName: string
  moduleName: string
  functionSubType: string
  sourceRepoType: string[]
  dataPlatforms: string[]
  functionName: string
  libraryType: string
  libraryPackageFile: string
  functionalVisibility: string
  inputParameters: string[]
  outputParameter: string
}

interface DataPlatform {
  id: string
  name: string
}
type InputField = {
  key: string
  type: string
  isRequired: boolean
  label: string
  value: string
}
const CustomFunctions: React.FC = () => {
  // State management with typed state variables
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [formData1, setformData1] = useState<FormData>({
    id: null,
    functionType: '',
    functionSubType: '',
    sourceRepoType: [],
    dataPlatforms: [],
    functionName: '',
    packageName: '',
    moduleName: '',
    libraryType: '',
    libraryPackageFile: '',
    functionalVisibility: '',
    inputParameters: [],
    outputParameter: '',
  })
  const [allTypes, setAllTypes] = useState<Type[]>([])
  const [allSubTypes, setAllSubTypes] = useState<SubType[]>([])
  const [allDbTypes, setAllDbTypes] = useState<DbType[]>([])
  const [allFileTypes, setAllFileTypes] = useState<FileType[]>([])
  const [allParameterTypes, setAllParameterTypes] = useState<ParameterType[]>(
    [],
  )
  const [userRoles, setUserRoles] = useState<any>()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [transformationFunctions, setTransformationFunctions] = useState([])
  const [transformationFunctionDetails, setTransformationFunctionDetails] =
    useState<TransformationFunction | null>()
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [dataPlatforms, setDataPlatforms] = useState<DataPlatform[]>([])
  const navigate = useNavigate()
  const [inputFields, setInputFields] = useState<InputField[]>([
    { key: '', type: '', isRequired: false, label: '', value: '' },
  ])
  const allParameterTypes1 = ['String', 'Number', 'Boolean'] // Example data types
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: 'Operation successful!',
  })
  const[isTested,setIsTested]=useState(false);
  const profileInfo = getProfileInfo()
  const handleAddField = () => {
    setInputFields([
      ...inputFields,
      { key: '', type: '', isRequired: false, label: '', value: '' }, // Updated structure
    ])
  }

  const [validationError, setValidationError] = useState('')

  const columns: GridColDef[] = [
    {
      field: 'transformation_function',
      headerName: 'Function',
      flex: 1,
      headerClassName: 'header-style',
    },
    {
      field: 'transformation_function_type',
      headerName: 'Type',
      flex: 1,
      headerClassName: 'header-style',
    },
    {
      field: 'transformation_function_sub_type',
      headerName: 'Sub Type',
      flex: 1,
      headerClassName: 'header-style',
    },
    {
      field: 'visibility',
      headerName: 'Scope',
      flex: 1,
      headerClassName: 'header-style',
    },
    {
      field: 'return_type',
      headerName: 'Output',
      flex: 1,
      headerClassName: 'header-style',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            handleEdit(params.row)
          }}
          sx={{
            color: '#42526E',
            textTransform: 'none',
            padding: '5px !important',
            border: 'none !important',
            background: 'transparent !important',
          }}
          disabled={userRoles && !userRoles?.edit}
        >
          <EditIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      ),
      sortable: false, // Disable sorting for the actions column
    },
  ]

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(profileInfoRoles?.orgRole?.permissions?.custom_functions)
    }
  }, [profileInfo, userRoles])

  const handleChange5 = (
    index: number,
    field: keyof InputField,
    value: string | boolean, // Depending on your InputField structure, isRequired should be boolean
  ) => {
    const newFields = [...inputFields]

    // Ensure to use type assertion for the field to avoid TypeScript errors
    newFields[index] = {
      ...newFields[index],
      [field]: field === 'isRequired' ? Boolean(value) : value, // Ensure correct type assignment
    }

    setInputFields(newFields)
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      // Set the selected file in the state
      setSelectedFile(file)

      // Update the formData1 to store the file name in libraryPackageFile
      setformData1((prevState) => ({
        ...prevState,
        libraryPackageFile: file.name, // Set the file name
      }))
    }
  }
  const getAllDataPlatforms1 = async () => {
    try {
      const response = await getAllDataPlatformsCustomFunctions()
      setDataPlatforms(response?.data || [])
    } catch (error: any) {
      console.error('Error fetching data platforms:', error)
    }
  }
  // Fetch types, subtypes, db types, parameter types on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const typesResponse = await getAllTypes()
        setAllTypes(typesResponse.data || [])

        const subTypesResponse = await getAllSubTypes()
        setAllSubTypes(subTypesResponse.data || [])

        const dbTypesResponse = await getAllDbTypes()
        setAllDbTypes(dbTypesResponse.data || [])

        const fileTypesResponse = await getAllFileTypes()
        setAllFileTypes(fileTypesResponse.data || [])

        // const customFunctionFileUpload=await fileUploadCustomFunction()

        const paramsTypesResponse = await getAllFunctionParameterTypes()
        setAllParameterTypes(paramsTypesResponse.data || [])
        await getAllDataPlatforms1()
      } catch (error: any) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const token = getToken() // Retrieve the token

  // Your useEffect hook with the file upload functionality
  useEffect(() => {
    // Function to handle file upload
    async function uploadFile() {
      // Check if a file is selected
      if (!selectedFile) {
        console.error('No file selected.')
        return
      }

      // Construct the API URL
      const apiUrl = `${environment.BASE_URL}/transformation_functions/file_upload`

      // Define the file and fileType
      const file = selectedFile as File
      const fileType = formData1.libraryType

      // Create a new formData1 instance
      const formData = new FormData()
      formData.append('file', file) // Append the file
      formData.append('fileType', fileType) // Append the fileType

      try {
        // Perform the file upload
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header
          },
        })

        // Check if the response is okay
        if (!response.ok) {
          throw new Error(`Failed to upload file: ${response.statusText}`)
        }

        // Parse the JSON response
        const data = await response.json()

        // Log the success message
        console.log('File uploaded successfully:', data)
      } catch (error: any) {
        // Log any errors
        console.error('Error uploading file:', error)
      }
    }

    // Call the uploadFile function when selectedFile changes
    uploadFile()
  }, [selectedFile]) // Dependency array - run the effect when selectedFile changes

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }
  useEffect(() => {
    setValidationError('')
  }, [formData1])
  const handleAdd = () => {
    setformData1({
      id: null,
      functionType: '',
      functionSubType: '',
      sourceRepoType: [],
      functionName: '',
      packageName: '',
      moduleName: '',
      libraryType: '',
      dataPlatforms: [],
      libraryPackageFile: '',
      functionalVisibility: '',
      inputParameters: [],
      outputParameter: '',
    })
    setInputFields([
      { key: '', type: '', isRequired: false, label: '', value: '' },
    ])
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleChange = (event: any) => {
    const name = event.target.name as keyof typeof formData1
    setformData1({ ...formData1, [name]: event.target.value as string })
  }

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setformData1({
  //       ...formData1,
  //       libraryPackageFile: event.target.files[0].name,
  //     })
  //   }
  // }

  const loadData = async () => {
    try {
      const resp: any = await ApiServices.getTransformationFunctions({
        // visibilities: ["ORG"],
      })

      const sourceList = resp.data.data
      setTransformationFunctions(sourceList)
    } catch (error: any) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    loadData()
    // loadDetails();
  }, [])
  const loadDetails = async (id: string) => {
    try {
      const resp: any = await ApiServices.getTransformationFunctionsDetail(id)

      setformData1({
        id: resp.data?.id || null,
        functionType: resp.data?.transformation_function_type || '',
        functionSubType: resp.data?.transformation_function_sub_type || '',
        sourceRepoType: resp.data?.db_type || [],
        functionName: resp.data?.transformation_function || '',
        packageName: resp.data?.package_name || '',
        moduleName: resp.data?.module_name || '',
        dataPlatforms: resp.data?.data_platforms_type || [],
        libraryType: resp.data?.file_type || '',
        libraryPackageFile: resp.data?.file_name || '',
        functionalVisibility: resp.data?.visibility || '',
        inputParameters: resp.data?.input_columns || [],
        outputParameter: resp.data?.output_column || '',
      })
      setInputFields(resp?.data?.params)
      setOpenDialog(true)
      setTransformationFunctionDetails(resp.data)
    } catch (error: any) {
      // setParamsOfTransformationFunction([])
      console.log('error', error)
    }
  }

  const handleEdit = async (functionData: any) => {
    await loadDetails(functionData?.id)

    //   setformData1({
    //     id:transformationFunctionDetails?.id || null,
    //     functionType:transformationFunctionDetails?.transformation_function_type || '',
    //     functionSubType:transformationFunctionDetails?.transformation_function_sub_type|| '',
    //     sourceRepoType: transformationFunctionDetails?.db_type ||[],
    //     dataPlatforms:transformationFunctionDetails?.data_platforms_type || [],
    //     functionName:transformationFunctionDetails?.transformation_function || '',
    //     libraryType:transformationFunctionDetails?.file_type || '',
    //     libraryPackageFile:transformationFunctionDetails?.file_name || '',
    //     functionalVisibility:transformationFunctionDetails?.visibility || '',
    //     inputParameters: transformationFunctionDetails?.input_columns ||[],
    //     outputParameter: transformationFunctionDetails?.output_column ||'',
    //   });
    //  setOpenDialog(true);
  }

  const handleSubmit = async () => {
    if (
      !formData1.functionType ||
      !formData1.functionSubType ||
      !formData1.libraryType ||
      !formData1.functionalVisibility ||
      !formData1.functionName ||
      !formData1.moduleName ||
      !formData1.packageName ||
      !formData1.libraryPackageFile ||
      !formData1.outputParameter
    ) {
      setValidationError('Please fill in all required fields.')
      return
    }

    const payload = {
      id: formData1?.id || null,
      transformation_function: formData1?.functionName,
      package_name: formData1?.packageName,
      module_name: formData1?.moduleName,
      transformation_function_description: formData1?.functionName,
      transformation_type: formData1?.functionType,
      transformation_sub_type: formData1?.functionSubType,
      db_type: formData1?.sourceRepoType,
      data_platforms_type: formData1?.dataPlatforms,
      file_name: formData1?.libraryPackageFile,
      file_type: formData1?.libraryType,
      input_columns: formData1?.inputParameters,
      output_column: formData1?.outputParameter,
      params: inputFields,
      return_type: 'string',
      visibility: formData1?.functionalVisibility,
    }

    try {
      const resp: any = await ApiServices.UpdateTransformationFunctions(payload)
      loadData()
      setOpenDialog(false)
      // setTransformationFunctionDetails(resp.data.params)
    } catch (error: any) {
      // setParamsOfTransformationFunction([])
      console.log('error', error)
    }
  }

  // const handleInputParametersChange = (event: any) => {
  //   setformData1({
  //     ...formData1,
  //     inputParameters: event.target.value as string[],
  //   })
  // }
  const handledbtypeChange = (event: any) => {
    setformData1({
      ...formData1,
      sourceRepoType: event.target.value as string[],
    })
  }
  const handledptypeChange = (event: any) => {
    setformData1({
      ...formData1,
      dataPlatforms: event.target.value as string[],
    })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Filtered and paginated data
  const filteredData = transformationFunctions.filter((func: any) =>
    func?.transformation_function
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  )

  const handleTestFunction = async () => {

    if (
      !formData1.functionType ||
      !formData1.functionSubType ||
      !formData1.libraryType ||
      !formData1.functionalVisibility ||
      !formData1.functionName ||
      !formData1.moduleName ||
      !formData1.packageName ||
      !formData1.libraryPackageFile ||
      !formData1.outputParameter
    ) {
      setValidationError('Please fill in all required fields.')
      return
    }


    const requestBody = {
      package_name: formData1.packageName,
      module_name: formData1.moduleName,
      function_name: formData1.functionName,
      parameters: inputFields,
    }

    try {
      const response = await fetch(
        'http://172.31.19.206:9090/test_custom_function',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      )

      if (!response.ok) {
        setAlertConfig({
          ...alertConfig,
          type: 'error',
          isOpen: true,
          message: response.statusText,
        })
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()

      // console.log('API response:', result)

      if (result.status === 'success') {
        // console.log('Result:', result?.data) // Output: 3.0
        setIsTested(true);
      
        setAlertConfig({
          ...alertConfig,
          isOpen: true,
          type: 'success',
          message: result?.data?.status
            ? `${result.data.status} Result : ${result?.data}`
            : `Custom function created Successfully  Result : ${result.data}`,
        })
      } else {
        setAlertConfig({
          ...alertConfig,
          type: 'error',
          isOpen: true,
          message: result.status,
        })
      }
    } catch (error: any) {
      // setAlertConfig({
      //   ...alertConfig,
      //   type: 'error',
      //   isOpen: true,
      //   message: error,
      // })
    }
  }
  // console.log(dataPlatforms);

  return (
    <Fragment>
      <Grid container>
        <SnackbarComponent
          setOpen={setAlertConfig}
          type={alertConfig.type}
          autoHideDuration={alertConfig.autoHideDuration}
          message={alertConfig.message}
          alertConfig={alertConfig}
          open={alertConfig.isOpen}
        />
        <Grid
          item
          xs={12}
          sx={{
            height: '87vh',
            marginTop: '10px',
            // paddingLeft: '25px',
            // paddingRight: '25px',
          }}
        >
          <Box
            sx={{
              fontSize: '24px',
              fontWeight: '500',
              marginBottom: '20px',
              lineHeight: '28px',
              marginTop: '37px',
            }}
          >
            Custom Functions
          </Box>

          <Box
            sx={{
              marginTop: '37px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              // justifyContent: 'space-between',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search functions"
              value={searchTerm}
              onChange={handleSearch}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ marginRight: '10px' }}
            />
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
              size="small"
              sx={{ padding: '10px 20px', fontWeight: '600' }}
            >
              Add Function
            </Button> */}
            <Button
              size={'small'}
              sx={{
                color: CUSTOM_COLORS.backgroundColor,
                textTransform: 'none',
                // background: CUSTOM_COLORS.tableRowBgLightGray,
              }}
              variant="text"
              startIcon={<Add />}
              disabled={userRoles && !userRoles?.create}
              onClick={handleAdd}
            >
              Add
            </Button>
          </Box>

          <DataGrid
            rows={paginatedData}
            columns={columns}
            // paginationModel={paginationModel}
            checkboxSelection
            // onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
            // pageSizeOptions={[5, 10, 20]} // Use pageSizeOptions instead of rowsPerPageOptions
            autoHeight
            sx={{
              '& .MuiDataGrid-footerContainer': {
                display: 'none',
              },

              marginTop: '37px',
            }}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      <Drawer
        anchor="left"
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ zIndex: 300 }}
      >
        <Box
          sx={{
            width: '550px',
            padding: '20px',
            marginTop: '70px',
            marginLeft: '70px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                setOpenDialog(false)
              }}
              // edge="start"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ paddingTop: '7px', marginLeft: '10px' }}
            >
              Add Function
            </Typography>
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              paddingRight: '30px',
            }}
          >
            <Grid container spacing={2}>
              {/* Function Type Select */}
              <Grid item xs={12}>
                {/* <Typography variant="body1" gutterBottom>
                  Function Type
                </Typography> */}
                <FormControl
                  fullWidth
                  size="small"
                  required
                  sx={{ marginTop: '20px' }}
                >
                  <DropdownComponent
                    name="functionType"
                    value={formData1.functionType}
                    onChange={handleChange}
                    label={'Function Type *'}
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Function Type
                    </MenuItem>
                    {allTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </DropdownComponent>
                </FormControl>
              </Grid>

              {/* Function Sub-Type Select */}
              <Grid item xs={12}>
                {/* <Typography variant="body1" gutterBottom>
                  Function Sub-Type
                </Typography> */}
                <FormControl
                  fullWidth
                  size="small"
                  required
                  sx={{ marginTop: '20px' }}
                >
                  <DropdownComponent
                    name="functionSubType"
                    value={formData1.functionSubType}
                    onChange={handleChange}
                    label={'Function Sub-Type *'}
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Function Sub-Type
                    </MenuItem>
                    {allSubTypes.map((subType) => (
                      <MenuItem key={subType.id} value={subType.id}>
                        {subType.name}
                      </MenuItem>
                    ))}
                  </DropdownComponent>
                </FormControl>
              </Grid>

              {/* Source Repository Type Select */}
              <Grid item xs={6}>
                {/* <Typography variant="body1" gutterBottom>
                  Source Repository Type
                </Typography> */}
                <FormControl
                  fullWidth
                  size="small"
                  required
                  sx={{ marginTop: '20px' }}
                >
                  <DropdownComponent
                    multiple
                    name="sourceRepoType"
                    value={formData1.sourceRepoType}
                    // onChange={handleChange}
                    label={'Source Repository Type *'}
                    onChange={handledbtypeChange}
                    renderValue={(selected) =>
                      (selected as string[]).join(', ')
                    }
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Repository Type
                    </MenuItem>
                    {/* {allDbTypes.map((dbType) => (
                      <MenuItem key={dbType.id} value={dbType.id}>
                        {dbType.name}
                      </MenuItem>
                    ))} */}
                    {allDbTypes.map((param) => (
                      <MenuItem key={param.id} value={param.id}>
                        <Checkbox
                          checked={formData1.sourceRepoType.includes(param.id)}
                        />
                        <ListItemText primary={param.name} />
                      </MenuItem>
                    ))}
                  </DropdownComponent>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {/* <Typography variant="body1" gutterBottom>
                  Data Platform Type
                </Typography> */}
                <FormControl
                  fullWidth
                  size="small"
                  required
                  sx={{ marginTop: '20px' }}
                >
                  <DropdownComponent
                    multiple
                    name="dataPlatforms"
                    value={formData1.dataPlatforms}
                    label={'Data Platform Type *'}
                    // onChange={handleChange}
                    onChange={handledptypeChange}
                    renderValue={(selected) =>
                      (selected as string[]).join(', ')
                    }
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Data Platform Type
                    </MenuItem>
                    {/* {dataPlatforms.map((dptypes:any) => (
                      <MenuItem key={dptypes.id} value={dptypes.id}>
                        {dptypes?.data_platforms_display_name}
                      </MenuItem>
                    ))} */}
                    {dataPlatforms.map((param: any) => (
                      <MenuItem key={param.id} value={param.id}>
                        <Checkbox
                          checked={formData1.dataPlatforms.includes(param.id)}
                        />
                        <ListItemText primary={param.name} />
                      </MenuItem>
                    ))}
                  </DropdownComponent>
                </FormControl>
              </Grid>

              {/* Library Type Select */}
              <Grid item xs={12}>
                {/* <Typography variant="body1" gutterBottom>
                  Library Type
                </Typography> */}
                <FormControl
                  fullWidth
                  size="small"
                  required
                  sx={{ marginTop: '20px' }}
                >
                  <DropdownComponent
                    name="libraryType"
                    value={formData1.libraryType}
                    onChange={handleChange}
                    label={'Library Type *'}
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Library Type
                    </MenuItem>
                    {/* <MenuItem value="lib1">Jar</MenuItem>
                    <MenuItem value="lib2">Python Egg</MenuItem>
                    <MenuItem value="lib2">Python Whl</MenuItem> */}
                    {allFileTypes.map((subType) => (
                      <MenuItem key={subType.id} value={subType.id}>
                        {subType.name}
                      </MenuItem>
                    ))}
                  </DropdownComponent>
                </FormControl>
              </Grid>

              {/* Upload Library Package File */}
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Upload Library Package *
                </Typography>
                {formData1.libraryPackageFile && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    Selected file: {formData1.libraryPackageFile}
                  </Typography>
                )}
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    type="file"
                    hidden
                    name="libraryPackageFile"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
              {/* Function Name Input */}
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Function Name *
                </Typography>
                <TextField
                  name="functionName"
                  value={formData1.functionName}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  required
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault() // Prevent space key from working
                    }
                  }}
                />
              </Grid>
              {/* module Name Input */}
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Module Name *
                </Typography>
                <TextField
                  name="moduleName"
                  value={formData1.moduleName}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  required
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault() // Prevent space key from working
                    }
                  }}
                />
              </Grid>
              {/* package Name Input */}
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Package Name *
                </Typography>
                <TextField
                  name="packageName"
                  value={formData1.packageName}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  required
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault() // Prevent space key from working
                    }
                  }}
                />
              </Grid>
              {/* Function Visibility Select */}
              <Grid item xs={12}>
                {/* <Typography variant="body1" gutterBottom>
                  Function Visibility
                </Typography> */}
                <FormControl
                  fullWidth
                  size="small"
                  required
                  sx={{ marginTop: '20px' }}
                >
                  <DropdownComponent
                    name="functionalVisibility"
                    value={formData1.functionalVisibility}
                    onChange={handleChange}
                    label={'Function Visibility *'}
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Visibility
                    </MenuItem>
                    <MenuItem value="ORG">Org</MenuItem>
                    <MenuItem value="PUBLIC">Public</MenuItem>
                  </DropdownComponent>
                </FormControl>
              </Grid>

              {/* Input Parameters Multi-Select */}
              {/* <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Input Parameters
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    multiple
                    name="inputParameters"
                    value={formData1.inputParameters}
                    onChange={handleInputParametersChange}
                    renderValue={(selected) =>
                      (selected as string[]).join(', ')
                    }
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Input Parameters
                    </MenuItem>
                    {allParameterTypes.map((param) => (
                      <MenuItem key={param.id} value={param.id}>
                        <Checkbox
                          checked={formData1.inputParameters.includes(param.id)}
                        />
                        <ListItemText primary={param.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              <Box sx={{ paddingLeft: '15px', marginTop: '15px' }}>
                {/* <Typography variant="body1" gutterBottom>
    Input Parameters
  </Typography> */}

                {/* Table Header */}
                <Grid
                  container
                  spacing={2}
                  sx={{ marginBottom: '10px', fontWeight: 'bold' }}
                >
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: '550' }} variant="body2">
                      Parameter Name
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontWeight: '550' }} variant="body2">
                      Data Type
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontWeight: '550' }} variant="body2">
                      Sample Value
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontWeight: '550' }} variant="body2">
                      Label
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography sx={{ fontWeight: '550' }} variant="body2">
                      Required
                    </Typography>
                  </Grid>
                </Grid>

                {/* Input Fields */}
                {inputFields.map((inputField, index) => (
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginBottom: '10px' }}
                    key={index}
                    alignItems="center"
                  >
                    {/* Parameter Name */}
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Name"
                        value={inputField.key}
                        onChange={(e) =>
                          handleChange5(index, 'key', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Data Type */}
                    <Grid item xs={2}>
                      <FormControl fullWidth size="small">
                        <Select
                          name="inputParameters"
                          value={inputField.type}
                          onChange={(e) =>
                            handleChange5(index, 'type', e.target.value)
                          }
                          renderValue={(selected) => selected}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {allParameterTypes.map((param) => (
                            <MenuItem key={param.id} value={param.id}>
                              <ListItemText primary={param.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Sample Value */}
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Value"
                        value={inputField.value}
                        onChange={(e) =>
                          handleChange5(index, 'value', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Label */}
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Label"
                        value={inputField.label}
                        onChange={(e) =>
                          handleChange5(index, 'label', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Required Checkbox */}
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={inputField.isRequired}
                            onChange={(e) =>
                              handleChange5(
                                index,
                                'isRequired',
                                e.target.checked,
                              )
                            }
                          />
                        }
                        label=""
                      />
                    </Grid>
                  </Grid>
                ))}

                {/* Add Button */}
                <Grid item xs={12}>
                  <IconButton onClick={handleAddField} color="primary">
                    <Add />
                  </IconButton>
                </Grid>
              </Box>

              {/* Output Parameter Single Select */}
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Output Parameter
                </Typography>
                <FormControl fullWidth size="small" required>
                  <Select
                    name="outputParameter"
                    value={formData1.outputParameter}
                    onChange={handleChange}
                    // displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Output Parameter
                    </MenuItem>
                    {allParameterTypes.map((param) => (
                      <MenuItem key={param.id} value={param.id}>
                        {param.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          {validationError && (
            <Typography
              variant="body1"
              color="error"
              sx={{ marginTop: '16px' }}
            >
              {validationError}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '24px',
            }}
          >
            <Button onClick={handleTestFunction}>Test Function</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ marginLeft: '16px' }}
              disabled={!isTested}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  )
}

export default CustomFunctions
