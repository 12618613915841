/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { ReactComponent as SourceIcon } from 'assets/icons/source.svg'
import { ReactComponent as TranformationIcon } from 'assets/icons/transformation.svg'
import { ReactComponent as TargetDestinationIcon } from 'assets/icons/target-destination.svg'

export const CONFIGURATION_INPUT_TYPES = {
  TEXTINPUT: 'TEXTINPUT',
  TEXTAREA: 'TEXTAREA',
  SINGLETEXT: 'SINGLETEXT',
  MULTITEXT: 'MULTITEXT',
  SINGLENODE: 'SINGLENODE',
  MULTINODE: 'MULTINODE',
  MULTIOBJECT: 'MULTIOBJECT',
  MULTICOLUMN: 'MULTICOLUMN',
  SINGLECOLUMN: 'SINGLECOLUMN',
  MULTITEXTINPUT: 'MULTITEXTINPUT',
  MULTIROW: 'MULTIROW',
  SINGLEFOLDER: 'SINGLEFOLDER',
  SINGLEFILE: 'SINGLEFILE',
  MULTIFOLDER: 'MULTIFOLDER',
}

export const VISUALIZATION_TEMPLATES_IDS = {
  VISUALIZATION_TEMPLATE_NETWORK_GRAPH_API_BASED:
    'VISUALIZATION_TEMPLATE_NETWORK_GRAPH_API_BASED',
}

export const NODE_TYPES_NAMES = {
  PIPELINE_01_DATA_SOURCE: {
    id: '6582aef3f8e8699af7d2fb46',
    componentNodeType: 'PIPELINE_01_DATA_SOURCE',
  },
  API_01_DATA_SOURCE: {
    id: '65b13421f4f9c7fb404f1741',
    componentNodeType: 'API_01_DATA_SOURCE',
  },
  VISUALIZATION_01_SUBCOMPONENT: {
    id: '65c10417ca95257907d5b6a6',
    componentNodeType: 'VISUALIZATION_01_SUBCOMPONENT',
  },
  PIPELINE_02_VIRTUAL_SOURCE: {
    id: '659142c706377aa006c45459',
    componentNodeType: 'PIPELINE_02_VIRTUAL_SOURCE',
  },
  PIPELINE_03_TRANSFORMATION_BLOCK: {
    id: '658fd22e292d6f3481337fd6',
    componentNodeType: 'PIPELINE_03_TRANSFORMATION',
  },
  PIPELINE_04_DATA_TARGET: {
    id: '659141de06377aa006c45458',
    componentNodeType: 'PIPELINE_04_DATA_TARGET',
  },
  API_02_API_OUTPUT: {
    id: '65b1385af4f9c7fb404f1742',
    componentNodeType: 'API_02_API_OUTPUT',
  },
  DOCUMENT_01_SOURCE: {
    id: '66156f25675b6a3ff5068aed',
    componentNodeType: 'DOCUMENT_01_SOURCE',
  },
  DOCUMENT_02_EXTRACTION: {
    id: '66156fa9675b6a3ff5068aee',
    componentNodeType: 'DOCUMENT_02_EXTRACTION',
  },
  DOCUMENT_02_PROCESSING: {
    id: '66156fa9675b6a3ff5068aee',
    componentNodeType: 'DOCUMENT_02_PROCESSING',
  },
  DOCUMENT_03_STAGING: {
    id: '66744d19db9f8caedf7c1659',
    componentNodeType: 'DOCUMENT_03_STAGING',
  },
  DOCUMENT_04_DATA_TARGET: {
    id: '661572aa675b6a3ff5068aef',
    componentNodeType: 'DOCUMENT_04_DATA_TARGET',
  },
  RETRIEVAL_01_REQUEST: {
    id: '666ece949f3d8f3cabf7634d',
    componentNodeType: 'RETRIEVAL_01_REQUEST',
  },
  RETRIEVAL_04_RESPONSE: {
    id: '666ed54d9f3d8f3cabf76350',
    componentNodeType: 'RETRIEVAL_04_RESPONSE',
  },
  RETRIEVAL_02_RAG: {
    id: '666ed3199f3d8f3cabf7634e',
    componentNodeType: 'RETRIEVAL_02_RAG',
  },
  DOCUMENT_031_STAGING: {
    id: '66744d80db9f8caedf7c165a',
    componentNodeType: 'DOCUMENT_031_STAGING',
  },
  RETRIEVAL_031_RAG: {
    id: '666ed4ad9f3d8f3cabf7634f',
    componentNodeType: 'RETRIEVAL_031_RAG',
  },
  DOCUMENT_011_SOURCE: {
    id: '6674fe780049bde2e9ff5d57',
    componentNodeType: 'DOCUMENT_011_SOURCE',
  },
  TRAINING_01_SOURCE: {
    id: '667bca1034da6319c53000a5',
    componentNodeType: 'TRAINING_01_SOURCE',
  },
  TRAINING_02_EXTRACTION: {
    id: '667bcac534da6319c53000a7',
    componentNodeType: 'TRAINING_02_EXTRACTION',
  },
  TRAINING_04_TARGET: {
    id: '667bcb2134da6319c53000a8',
    componentNodeType: 'TRAINING_04_TARGET',
  },
  TRAINING_011_SOURCE: {
    id: '667bca5e34da6319c53000a6',
    componentNodeType: 'TRAINING_011_SOURCE',
  },
  TRAINING_021_EXTRACTION: {
    id: '667be38b34da6319c53000ae',
    componentNodeType: 'TRAINING_021_EXTRACTION',
  },
  DQ_01_SOURCE_REPOSITORY: {
    id: '669a3e48d4362624521f03b1',
    componentNodeType: 'DQ_01_SOURCE_REPOSITORY',
  },
  DQ_02_CHECKS: {
    id: '669a3eccd4362624521f03b2',
    componentNodeType: 'DQ_02_CHECKS',
  },
  DQ_03_METRICS: {
    id: '669a3f8fd4362624521f03b4',
    componentNodeType: 'DQ_03_METRICS',
  },
  DQ_04_TARGET_REPOSITORY: {
    id: '669a403cd4362624521f03b6',
    componentNodeType: 'DQ_04_TARGET_REPOSITORY',
  },
  TRAINING_041_TARGET: {
    id: '66a8e0358bd9bbf3efe1860f',
    componentNodeType: 'TRAINING_041_TARGET',
  },
  STREAMING_01_DATA_SOURCE: {
    id: '670cc9ffa315f588bc7d801d',
    componentNodeType: 'STREAMING_01_DATA_SOURCE',
  },
  STREAMING_03_TRANSFORMATION: {
    id: '670ccae4a315f588bc7d801e',
    componentNodeType: 'STREAMING_03_TRANSFORMATION',
  },
  STREAMING_04_DATA_TARGET: {
    id: '671b606e536a07a83b25b6ac',
    componentNodeType: 'STREAMING_04_DATA_TARGET',
  },
  DOCUMENT_03_CHUNKING: {
    id: '66892dddb970cd5ebb1c0736',
    componentNodeType: 'DOCUMENT_03_CHUNKING',
  },
}

export const NODE_TEMPLATE_NAMES = {
  API_TEMPLATE_NETWORK_GRAPHDB_BASED: 'API_TEMPLATE_NETWORK_GRAPHDB_BASED',
}

export const NodeTypesDetailsData = [
  {
    id: '6582aef3f8e8699af7d2fb46',
    componentNodeType: 'PIPELINE_01_DATA_SOURCE',
    componentNodeTypeName: 'Source',
    icon: SourceIcon,
  },
  {
    id: '65b13421f4f9c7fb404f1741',
    componentNodeType: 'API_01_DATA_SOURCE',
    componentNodeTypeName: 'Source',
    icon: SourceIcon,
  },
  {
    id: '65c10417ca95257907d5b6a6',
    componentNodeType: 'VISUALIZATION_01_SUBCOMPONENT',
    componentNodeTypeName: 'Source',
    icon: SourceIcon,
  },
  {
    id: '659142c706377aa006c45459',
    componentNodeType: 'PIPELINE_02_VIRTUAL_SOURCE',
    componentNodeTypeName: 'Virtual Source',
    icon: SourceIcon,
  },
  {
    id: '658fd22e292d6f3481337fd6',
    componentNodeType: 'PIPELINE_03_TRANSFORMATION',
    componentNodeTypeName: 'Transformation Block',
    icon: TranformationIcon,
  },
  {
    id: '659141de06377aa006c45458',
    componentNodeType: 'PIPELINE_04_DATA_TARGET',
    componentNodeTypeName: 'Target',
    icon: TargetDestinationIcon,
  },
  {
    id: '65b1385af4f9c7fb404f1742',
    componentNodeType: 'API_02_API_OUTPUT',
    componentNodeTypeName: 'API Output',
    icon: TargetDestinationIcon,
  },
  //DQ Pipeline
  // {
  //   id: '669a3e48d4362624521f03b1',
  //   componentNodeType: 'DQ_01_SOURCE_REPOSITORY',
  //   componentNodeTypeName: 'Transformation Block',
  //   icon: TranformationIcon,
  // },
]

export const TransformationConfigurationId = '658fd8c3292d6f3481337fd7'
export const DocumentProcessingConfigurationId = '661e91b4d9a2499d02ed1e0d'
export const TransformationConfigurationIdDataFrame = '6623ad80f0d7f5bc71da703d'
export const ModelDataPreparationConfigurationId = '6707ac7866faefab043d97a1'

export const TransformationTemplateColumnLevelId = '661e495e01cf8a4bef0c28cb'
export const TransformationTemplateDataFrameLevelId = '661e4a7c01cf8a4bef0c28cc'

export const knowledgeRetrievalSourceTransformationId =
  '667c1fa534da6319c53000af'
export const ResponseBuilderChildNodeTransformationId =
  '66f1586e55103ce67f3c9e3f'
export const edge_transformationsId = '66fa5aa223b2ceb64dc9688d'

export const DefaultTranformerPopup = {
  isEdit: false,
  open: false,
  id: null,
  nodeName: '',
}

const NodeIdAndNameForPipelines: any = {
  DATA_PIPELINE: {
    source: {
      id: '6582aef3f8e8699af7d2fb46',
      componentNodeType: 'PIPELINE_01_DATA_SOURCE',
    },
  },
  DATA_ACCESS_API: {
    source: {
      id: '65b13421f4f9c7fb404f1741',
      componentNodeType: 'API_01_DATA_SOURCE',
    },
  },
  DATA_VISUALIZATION: {
    source: {
      id: '65c10417ca95257907d5b6a6',
      componentNodeType: 'VISUALIZATION_01_SUBCOMPONENT',
    },
  },
  DOCUMENT_PROCESSING: {
    source: {
      id: '66156f25675b6a3ff5068aed',
      componentNodeType: 'DOCUMENT_01_SOURCE',
    },
  },
  KNOWLEDGE_RETRIEVAL: {
    source: {
      id: '666ece949f3d8f3cabf7634d',
      componentNodeType: 'RETRIEVAL_01_REQUEST',
    },
  },
  MODEL_TRAINING: {
    source: {
      id: '667bca1034da6319c53000a5',
      componentNodeType: 'TRAINING_01_SOURCE',
    },
  },
  DQ_PIPELINE: {
    source: {
      id: '669a3e48d4362624521f03b1',
      componentNodeType: 'DQ_01_SOURCE_REPOSITORY',
    },
  },
  STREAMING_DATAFLOW: {
    source: {
      id: '670cc9ffa315f588bc7d801d',
      componentNodeType: 'STREAMING_DATAFLOW',
    },
  },
}

export const GET_NODE_TYPES = (pipelineType: string) => {
  return {
    source: {
      id: NodeIdAndNameForPipelines[pipelineType].source.id,
      componentNodeType:
        NodeIdAndNameForPipelines[pipelineType].source.componentNodeType,
      componentNodeTypeName: 'Source',
      keys: {
        source_repository: 'source_repository',
        input_table: 'input_table',
        retain_columns: 'retain_columns',
        dq_metrics: 'dq_metrics',
        filter_columns: 'filter_columns',
        input_node: 'input_node',
        output_type: 'output_type',
        input_field_mapping: 'input_field_mapping',
        output_field_mapping: 'output_field_mapping',
        //Data Visualization
        node_template: 'node_template',
        data_acess_api: 'data_acess_api',
        retain_columns_01: 'retain_columns_01',
        retain_columns_02: 'retain_columns_02',
        retain_columns_03: 'retain_columns_03',
        series_label_01: 'series_label_01',
        series_label_02: 'series_label_02',
        dq_checks: 'dq_checks',
        endpoint_node: 'endpoint_node',
        input_path: 'input_path',
      },
    },
    virtual_source: {
      id: '659142c706377aa006c45459',
      componentNodeType: 'PIPELINE_02_VIRTUAL_SOURCE',
      componentNodeTypeName: 'Virtual Source',
      keys: {},
    },
    transformation: {
      id: '658fd22e292d6f3481337fd6',
      componentNodeType: 'PIPELINE_03_TRANSFORMATION',
      componentNodeTypeName: 'Transformation',
      keys: {
        edge_filter_columns: 'edge_filter_columns',
        edge_retain_columns: 'edge_retain_columns',
        input_node: 'input_node',
        input_data_frame: 'input_dataframe',
        retain_columns: 'retain_columns',
        output_data_frame: 'output_data_frame',
        transform_functions: 'operation',
        transformations_to_apply: 'transformations',
        input_columns: 'input_columns',
        output_column: 'output_column',
        target_repository: 'target_repository',
        output_table: 'output_table',
        filter_columns: 'filter_columns',
        output_type: 'output_type',
        input_field_mapping: 'input_field_mapping',
        output_field_mapping: 'output_field_mapping',
      },
    },
    documentProcessing: {
      id: '66156fa9675b6a3ff5068aee',
      componentNodeType: 'DOCUMENT_02_PROCESSING',
      componentNodeTypeName: 'Processing Block',
      keys: {
        llm_model: 'llm_model',
        input_node: 'input_node',
        input_data_frame: 'input_dataframe',
        retain_columns: 'retain_columns',
        output_data_frame: 'output_data_frame',
        transform_functions: 'operation',
        transformations_to_apply: 'transformations',
        input_columns: 'input_columns',
        output_column: 'output_column',
        input_variables: 'input_variables',
        output_variable: 'output_variable',
        target_repository: 'target_repository',
        output_table: 'output_table',
        filter_columns: 'filter_columns',
        output_type: 'output_type',
        input_field_mapping: 'input_field_mapping',
        output_field_mapping: 'output_field_mapping',
        transformations: 'transformations',
        output_queue: 'output_queue',
        input_folder: 'input_folder',
        file_format: 'file_format',
        dq_metric_function: 'dq_metric_function',
        dq_check_function: 'dq_check_function',
        master_index_repository: 'master_index_repository',
        master_index_table: 'master_index_table',
        master_index_variables: 'master_index_variables',
        input_vectors: 'input_vectors',
        output_vector: 'output_vector',
      },
    },
    target: {
      id: '659141de06377aa006c45458',
      componentNodeType: 'PIPELINE_04_DATA_TARGET',
      componentNodeTypeName: 'Target',
      keys: {
        target_repository: 'target_repository',
        output_table: 'output_table',
        retain_columns: 'retain_columns',
        filter_columns: 'filter_columns',
        input_node: 'input_node',
        output_type: 'output_type',
        input_field_mapping: 'input_field_mapping',
        output_field_mapping: 'output_field_mapping',
        input_data_frame: 'input_dataframe',
        dq_checks_repository: 'dq_checks_repository',
        dq_checks_table: 'dq_checks_table',
        assertions_input_dataframe: 'assertions_input_dataframe',
      },
    },
    knowledgeRetrieval: {
      id: '666ed3199f3d8f3cabf7634e',
      componentNodeType: 'RETRIEVAL_02_RAG',
      componentNodeTypeName: 'Retrieval Augmented Generation Block',
      keys: {
        stt_model: 'stt_model',
        translation_model: 'translation_model',
        input_node: 'input_node',
        source_repository: 'source_repository',
        embedding_model: 'embedding_model',
      },
    },
    streamingDataFlow: {
      id: '670cc9ffa315f588bc7d801d',
      componentNodeType: 'STREAMING_DATAFLOW',
      componentNodeTypeName: 'Streaming Dataflow',
      keys: {
        input_path: 'input_path',
      },
    },
  }
}

export const COMPONENT_TYPES = {
  DATA_PIPELINE: 'DATA_PIPELINE',
  DATA_ACCESS_API: 'DATA_ACCESS_API',
  DATA_VISUALIZATION: 'DATA_VISUALIZATION',
  DOCUMENT_PROCESSING: 'DOCUMENT_PROCESSING',
  KNOWLEDGE_RETRIEVAL: 'KNOWLEDGE_RETRIEVAL',
  MODEL_TRAINING: 'MODEL_TRAINING',
  DQ_PIPELINE: 'DQ_PIPELINE',
  STREAMING_DATAFLOW: 'STREAMING_DATAFLOW',
}

export const MODEL_INSTANCE_ID = {
  EMBEDDING_MODEL: '669f72f25290d9e0167eee5b',
}

export const NODE_TEMPLATE_ID = {
  DATA_PIPELINE_SOURCE_FILE_SYSTEM: '6745507567edf5a21f8a43be',
}
