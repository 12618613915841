/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import {
  Box,
  TextField,
  Button,
  List,
  Paper,
  Typography,
  IconButton,
  keyframes,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material'
import UserMessageCard from './components/UserMessageCard'
import BotMessageCard from './components/BotMessageCard'
import SendIcon from '@mui/icons-material/Send'
import MicIcon from '@mui/icons-material/Mic'
import CloseIcon from '@mui/icons-material/Close'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'
import { environment } from 'environments/environment'
import { getVerifySupport } from 'api/services/api-services'
const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

interface ChatbotProps {
  selectedComponent: any
  open: any
  onClose: any
}

interface Message {
  sender: 'user' | 'bot'
  text: string
  data: any
  audio?: string | null // Add audio field to store audio URL
}

const Chatbot: React.FC<ChatbotProps> = ({
  selectedComponent,
  open,
  onClose,
}) => {
  const [messages, setMessages] = useState<Message[]>([])
  const [input, setInput] = useState<string>('')
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recording, setRecording] = useState(false)
  const [timer, setTimer] = useState(0)
  const mediaRecorder = useRef<MediaRecorder | null>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const [audioFlag,setAudioFlag]=useState(false);
  // Start recording
  const startRecording = async () => {
    setTimer(0)
    setRecording(true)

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const recorder = new MediaRecorder(stream)
    mediaRecorder.current = recorder

    const audioChunks: BlobPart[] = []
    recorder.ondataavailable = (event) => {
      audioChunks.push(event.data)
    }

    recorder.onstop = () => {
      const audio = new Blob(audioChunks, { type: 'audio/webm' })
      setAudioBlob(audio)
      // sendMessage();
    }

    recorder.start()

    intervalRef.current = setInterval(() => {
      setTimer((prev) => prev + 1)
    }, 1000)
  }
  const [selectedComponentId, setSelectedComponentId] = useState(
    selectedComponent[0]?.uid,
  )
  const [selectedComponentCID, setSelectedComponentCID] = useState(
    selectedComponent[0]?.id,
  )

  const handleSelectChange = (event: any) => {
    setSelectedComponentId(event.target.value)
  }
  // Stop recording
  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop()
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    setRecording(false)
  }
  useEffect(() => {
    sendMessage()
  }, [audioBlob])
  useEffect(()=>{ getShowAudio()},[selectedComponentId])
  // Send message to the API
  const sendMessage = async () => {
    if (input.trim() === '' && !audioBlob) return

    const formData = new FormData()
    // formData.append("query", input);
    // if (audioBlob) {
    formData.append('audio', audioBlob || '')
    // }
    // formData.append("index", "court_cases");

    const audioURL = audioBlob ? URL.createObjectURL(audioBlob) : null // Create URL for the audio blob

    const userMessage: Message = {
      sender: 'user',
      text: input,
      data: [],
      audio: audioURL,
    } // Add audio URL to message
    setMessages((prevMessages) => [...prevMessages, userMessage])
    setInput('')

    // Reset audio state after sending
    setAudioBlob(null)
    setTimer(0)

    try {
      const response = await axios.post(
        `${environment.CHATBOT_PYTHON}/rag_retrival?componentId=${selectedComponentId}&query=${input}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      const botMessage: Message = {
        sender: 'bot',
        text: response.data[0] || 'No response',
        data: response.data[0],
      }
      setMessages((prevMessages) => [...prevMessages, botMessage])
    } catch (error: any) {
      console.error('Error:', error)

      const errorMessage: Message = {
        sender: 'bot',
        text: 'Something went wrong. Please try again.',
        // data: error?.response?.data?.message,
        data: 'Something went wrong. Please try again.',
      }
      setMessages((prevMessages) => [...prevMessages, errorMessage])
    }
  }

  if (!open) return null

  const getShowAudio = async () => {
    try {
      const response = await getVerifySupport(
        selectedComponent.find((component: { uid: any }) => component.uid === selectedComponentId)?.id
    );
          setAudioFlag(response?.data?.message);
    } catch (error: any) {
      setAudioFlag(false);
      console.error('Error fetching data platforms:', error)
    }
  }

// console.log(selectedComponent);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: '5px',
        borderRadius: '12px',
        width: '100%',
        height: '85vh',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '12px',
          background: 'linear-gradient(145deg, #0d47a1, #1565c0)',
          padding: '12px 16px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
          position: 'relative',
          color: '#FFF',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h6"
            sx={{
              color: '#FFF',
              fontWeight: '600',
              fontSize: '18px',
              marginRight: '16px',
            }}
          >
            Ask our AI
          </Typography>

          <FormControl
            variant="outlined"
            size="small"
            sx={{ minWidth: 250, backgroundColor: '#FFF', borderRadius: '8px' }}
          >
            {/* <InputLabel id="component-select-label" sx={{ color: '#333' }}>Select Component</InputLabel> */}
            <Select
              labelId="component-select-label"
              label="Select Component"
              value={selectedComponentId}
              onChange={handleSelectChange}
              sx={{
                '& .MuiSelect-icon': { color: '#1565c0' },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
            >
              {selectedComponent.map((component: any) => (
                <MenuItem key={component.uid} value={component.uid}>
                  {component.component_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <IconButton
          onClick={onClose}
          sx={{
            color: '#FFF',
            position: 'absolute',
            right: '10px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          mb: 2,
          p: 2,
          bgcolor: 'background.default',
          borderRadius: 1,
          width: '98%',
        }}
      >
        <List>
          {messages.map((msg, index) =>
            msg.sender === 'user' ? (
              <UserMessageCard key={index} text={msg.text} audio={msg.audio} /> // Pass audio prop to UserMessageCard
            ) : (
              <BotMessageCard key={index} data={msg.data} />
            ),
          )}
        </List>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {audioFlag &&<Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={recording ? stopRecording : startRecording}
          sx={{ color: recording ? 'red' : '#0d47a1' }}
        >
          <MicIcon />
        </IconButton>

   
        {recording && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '8px',
              height: '8px',
              backgroundColor: 'red',
              borderRadius: '50%',
              animation: `${fadeInOut} 1s infinite ease-in-out`,
            }}
          />
        )}
      </Box>}

          {/* <Typography sx={{ marginTop: '5px' }}>{timer}s</Typography> */}
        </Box>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Ask a question..."
          value={input}
          size="small"
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              sendMessage()
            }
          }}
        />

        <Button
          variant="outlined"
          size="small"
          onClick={sendMessage}
          sx={{ ml: 2, color: '#0d47a1', border: '2px solid #0d47a1' }}
          endIcon={<SendIcon sx={{ color: '#0d47a1' }} />}
        >
          Send
        </Button>
      </Box>
    </Paper>
  )
}

export default Chatbot
