/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Icon,
  IconButton,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert' // Import MoreVert icon

import { darkStyles, styles } from '../style'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useClasses from 'hooks/styleHook'
import { darkThemeStyle, style } from 'utility/stylesheet'
import useCytoscapeHook from 'hooks/useCytoscape'
import { dagreLayout } from 'utility/cytoscape-layout'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { renderToString } from 'react-dom/server'
import GraphControl from 'pages/projects/data-pipeline/graph-control'
import { ReactComponent as SourceIcon } from 'assets/icons/source.svg'
import NodeDetailsDrawer from 'pages/projects/data-pipeline/node-details-drawer'
import {
  COMPONENT_TYPES,
  GET_NODE_TYPES,
  NODE_TYPES_NAMES,
  NodeTypesDetailsData,
} from './data-pipeline-constants'
import { removeDuplicates } from 'utility/utility'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Check } from '@mui/icons-material'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
import { Variant } from 'types/props-types'
import Dialog from 'components/molecules/Dialog'
import ComponentList from './ComponentList'
import OutputTemplateList from './OutputTemplateList'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import ChangeComponentName from './ChangeComponentName'
import { useTheme } from 'ThemeContext'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'
import { setComponentName } from 'store/breadcrumbSlice'
import { disableComponentNode } from 'api/services/api-services'
import ConfirmationDialog from 'components/molecules/ConfirmationDialog'

interface NodeDrawerPropertyInterface {
  nodeType: string
  show: boolean
  isEdit: boolean
  nodeAttached?: any
}

const DefaultNodeProperty: NodeDrawerPropertyInterface = {
  nodeType: '',
  show: false,
  isEdit: false,
  nodeAttached: null,
}
type DisableNodeData = {
  projectId: string | null
  componentId: string | null
  componentNodeIds: string | null
}
const DataPipeline = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { isDarkMode, handleThemeChange } = useTheme()

  const [isLoading, setLoading] = useState(false)
  const classes = useClasses(isDarkMode ? darkStyles : styles)
  const graphRef = useRef()

  const { cy, initCy } = useCytoscapeHook({
    stylesheet: isDarkMode ? darkThemeStyle : style,
  })
  const [cyInstance, setCyInstance] = useState<any>()
  const [searchParams] = useSearchParams()
  const [openDialog, setOpenDialog] = useState(false)
  const [componentId, setComponentId] = useState<any>()
  const [componentTypeValue, setComponentTypeValue] = useState<any>()
  const [projectId, setProjectId] = useState<any>()
  const [componentDetailsData, setComponentDetailsData] = useState<any>()
  const [componentNodeTypeData, setComponentNodeTypeData] = useState<any>()
  const componentNodeTypeDataRef = useRef<any>()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [nodeDeleteFlag, setNodeDeleteFlag] = useState(false)
  const [showNodePropertiesDrawer, setNodePropertiesDrawer] =
    useState(DefaultNodeProperty)
  const [NODE_TYPES, setNodeTypes] = useState<any>('')
  const [selectedNode, setSelectedNode] = useState<any>()
  const [disableNodeData, setDisableNodeData] = useState<DisableNodeData>({
    projectId: null,
    componentId: null,
    componentNodeIds: null,
  })
  const nodeDrawerRef = useRef<any>()
  const [NodeTypesDetailsDataForNodeMenu, setNodeDetailsDataForNodeMenu] =
    useState<any>([])
  const [openChangeComponentNameDialog, setOpenChangeComponentNameDialog] =
    useState(false)
  const [isNameChange, setIsNameChange] = useState(false)

  const [projectRole, setProjectRole] = useState<any>()
  const [componentTypesForJsonCon] = useState([
    'DATA_PIPELINE',
    'DOCUMENT_PROCESSING',
    'KNOWLEDGE_RETRIEVAL',
    'MODEL_TRAINING',
    'DQ_PIPELINE',
    'DATA_ACCESS_API',
    'STREAMING_DATAFLOW',
  ])

  const [outputTypeDialog, setOutputTypeDialog] = useState<any>({
    isOpen: false,
    data: {
      item: null,
      event: null,
    },
  })

  const [visualizationTemplatesDialog, setVisualizationTemplatesDialog] =
    useState<any>({ isOpen: false, component_node_type_id: '' })
  const [popoverNodeData, setPopoverNodeData] = useState<any>(null)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(
    null,
  )

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  // Example function for rendering the menu

  const [transformationTemplatesDialog, setTransformationTemplatesDialog] =
    useState<any>({
      isOpen: false,
      component_node_type_id: '',
      clickedNode: null,
    })

  const [
    modelPreparationBlockTemplatesDialog,
    setModelPreparationBlockTemplatesDialog,
  ] = useState<any>({
    isOpen: false,
    component_node_type_id: '',
    clickedNode: null,
  })

  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: '',
  })

  useEffect(() => {
    if (componentDetailsData) {
      const NODE_TYPES = GET_NODE_TYPES(componentDetailsData?.component_type)
      setNodeTypes(NODE_TYPES)
    }
  }, [componentDetailsData])

  useEffect(() => {
    if (!showNodePropertiesDrawer.show) {
      nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
      setSelectedNode(null)
    }
  }, [showNodePropertiesDrawer])

  useEffect(() => {
    if (nodeDeleteFlag) handleComponentDelete()
    setNodeDeleteFlag(false)
  }, [nodeDeleteFlag])

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const handleComponentDelete = useCallback(() => {
    const payload = {
      project_id: disableNodeData.projectId,
      component_id: disableNodeData.componentId,
      component_node_ids: [disableNodeData.componentNodeIds],
    }

    disableComponentNode(payload)
      .then((response) => {
        getProjectDetails()
        // Optionally, update UI or state, e.g., close dialog or update nodes
        setOpenDeleteDialog(false)
      })
      .catch((error) => {
        console.error('Error deleting node:', error)
      })
  }, [disableNodeData])

  const setPermission = useCallback(
    (permissions: any) => {
      switch (componentTypeValue) {
        case COMPONENT_TYPES.DATA_PIPELINE:
          return permissions?.data_pipeline
        case COMPONENT_TYPES.DATA_ACCESS_API:
          return permissions?.data_access_api
        case COMPONENT_TYPES.DATA_VISUALIZATION:
          return permissions?.data_visualization
        case COMPONENT_TYPES.DOCUMENT_PROCESSING:
          return permissions?.document_processing
        case COMPONENT_TYPES.KNOWLEDGE_RETRIEVAL:
          return permissions?.knowledge_retrieval
        case COMPONENT_TYPES.MODEL_TRAINING:
          return permissions?.model_training
        case COMPONENT_TYPES?.DQ_PIPELINE:
          return permissions?.dq_pipeline
        case COMPONENT_TYPES?.STREAMING_DATAFLOW:
          return permissions?.streaming_dataflow
      }
    },
    [COMPONENT_TYPES, componentTypeValue],
  )

  useEffect(() => {
    const getNodeTypes = async () => {
      try {
        setLoading(true)
        const resp = await ApiServices.getComponentNodeType(componentTypeValue)
        setComponentNodeTypeData(resp.data)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
      }
    }

    const getProjRoleDetails = async () => {
      try {
        setLoading(true)
        const projRoles: any =
          await ApiServices.getProjectRoleDetails(projectId)

        setProjectRole(setPermission(projRoles.data.role.permissions))
        setLoading(false)
      } catch (error: any) {
        console.log('error', error)
      }
    }

    if (componentTypeValue) {
      getNodeTypes()
    }
    if (projectId) {
      getProjRoleDetails()
    }
  }, [componentTypeValue, projectId])

  const handleShowAlert = useCallback(
    (msg: string, type: Variant = 'success') => {
      setAlertConfig({
        ...alertConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [alertConfig],
  )

  const addNewSourceNode = useCallback(() => {
    if (componentDetailsData?.component_type === 'DATA_VISUALIZATION') {
      setVisualizationTemplatesDialog({
        isOpen: true,
        component_node_type_id: NODE_TYPES.source.id,
      })
    } else if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
      setVisualizationTemplatesDialog({
        isOpen: true,
        component_node_type_id: NODE_TYPES.source.id,
      })
    } else if (componentDetailsData?.component_type === 'DATA_PIPELINE') {
      setVisualizationTemplatesDialog({
        isOpen: true,
        component_node_type_id: NODE_TYPES.source.id,
      })
    } else {
      setSelectedNode({
        id: null,
        component_node_name: NODE_TYPES.source.componentNodeTypeName,
        component_node_type_id: NODE_TYPES.source.id,
      })
      nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
      setNodePropertiesDrawer({
        nodeType: NODE_TYPES.source.componentNodeType,
        show: true,
        isEdit: false,
      })
      nodeDrawerRef &&
        nodeDrawerRef?.current?.getComponentNodeDetailsConfiguration({
          nodeTypeId: NODE_TYPES.source.id,
        })
    }
  }, [NODE_TYPES, componentDetailsData, projectRole])

  const options = useMemo(() => {
    const location = useLocation()
    const query = useQuery()

    const projectId = query.get('projectId')
    const componentId = query.get('componentId')

    const menuItemsData = NodeTypesDetailsDataForNodeMenu.map((item: any) => {
      return {
        id: item.id,
        content: `Add new ${item.componentNodeTypeName} node`,
        tooltipText: `Add new ${item.componentNodeTypeName} node`,
        selector: 'node',
        onClickFunction: function (event: any) {
          const node = event.target
          if (item.componentNodeTypeName === 'API Output') {
            setOutputTypeDialog({
              isOpen: true,
              data: { item: item, event: event.target },
            })
          } else if (item.id === NODE_TYPES.transformation.id) {
            setTransformationTemplatesDialog({
              isOpen: true,
              component_node_type_id: NODE_TYPES.transformation.id,
              clickedNode: node,
            })
          } else if (item.id === NODE_TYPES_NAMES.TRAINING_02_EXTRACTION.id) {
            setModelPreparationBlockTemplatesDialog({
              isOpen: true,
              component_node_type_id:
                NODE_TYPES_NAMES.TRAINING_02_EXTRACTION.id,
              clickedNode: node,
            })
          } else {
            const component_node_type_id = node.data('component_node_type_id')
            const nodeType = component_node_type_id
            setNodePropertiesDrawer({
              nodeType,
              show: true,
              isEdit: false,
              nodeAttached: {
                sourceId: node.data('id'),
              },
            })
            setSelectedNode({
              id: null,
              component_node_name: item.componentNodeTypeName,
              component_node_type_id: item.id,
            })
            nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
            nodeDrawerRef &&
              nodeDrawerRef?.current?.getComponentNodeDetailsConfiguration({
                nodeTypeId: item.id,
                originNodeDetail: {
                  id: node.data('id'),
                  component_node_name: node.data('component_node_name'),
                  newNodeTypeName: item.componentNodeTypeName,
                },
              })
          }
        },
        hasTrailingDivider: true,
      }
    })

    menuItemsData.push({
      id: 'delete-node',
      content: `🗑️ Delete node`, // other designs = &#x274C=❌,
      tooltipText: 'Delete this node',
      selector: 'node',
      onClickFunction: function (event: any) {
        const node = event.target
        const nodeId = node.data('id')

        setDisableNodeData({
          projectId: projectId,
          componentId: componentId,
          componentNodeIds: nodeId,
        })

        // console.log(disableNodeData);

        setOpenDeleteDialog(true)
        // const projectId = '6667cee3483fb4fd25b1ffa9'; // Replace with your actual project ID
        // const componentId = '97e2589f93473038b80d80a8'; // Replace with your actual component ID
      },
      hasTrailingDivider: false,
    })

    if (projectRole && projectRole?.edit_nodes) {
      return {
        evtType: 'cxttap',
        menuItems: menuItemsData,
        menuItemClasses: ['custom-menu-item', 'custom-menu-item:hover'],
        contextMenuClasses: ['custom-context-menu'],
      }
    }
  }, [NodeTypesDetailsDataForNodeMenu, NODE_TYPES, projectRole])

  const contextMenuInstance = useMemo(() => {
    if (projectRole && projectRole?.edit_nodes) {
      return cyInstance && cyInstance.contextMenus(options)
    }
  }, [cyInstance, options, projectRole])

  const getProjectDetails = useCallback(async () => {
    try {
      setLoading(true)
      const resp: any = await ApiServices.getComponentDetails(componentId)
      setLoading(false)
      setComponentDetailsData({ ...resp.data })
      dispatch(setComponentName(resp?.data?.component_name))
    } catch (error: any) {
      setComponentDetailsData(null)
      setLoading(false)
      console.log('error', error)
    }
  }, [componentId])

  useEffect(() => {
    if (searchParams) {
      const component_Id = searchParams.get('componentId')
      const project_Id = searchParams.get('projectId')
      const componentType = searchParams.get('componentType')
      setComponentId(component_Id)
      setProjectId(project_Id)
      setComponentTypeValue(componentType)
    }
  }, [searchParams])

  useEffect(() => {
    if (componentId) {
      getProjectDetails()
    }
  }, [componentId, getProjectDetails, isNameChange])

  useEffect(() => {
    componentNodeTypeDataRef.current = componentNodeTypeData
    let filteredNodeTypes: any = []
    if (componentNodeTypeData) {
      filteredNodeTypes = componentNodeTypeData
        .filter(
          (item: any) =>
            item.target_component_node_type &&
            item.target_component_node_type.length > 0,
        )
        .map((item: any) => {
          return item.target_component_node_type
        })
        .flat()
        .map((item: any) => {
          return {
            id: item._id,
            componentNodeType: item.componentNodeType,
            componentNodeTypeName: item.componentNodeTypeName,
          }
        })
      filteredNodeTypes = removeDuplicates(filteredNodeTypes, ['id'])
    }
    setNodeDetailsDataForNodeMenu(filteredNodeTypes)
  }, [componentNodeTypeData])

  const onNodeRightClicked = useCallback(
    (node: any) => {
      console.log(node);
      
      // const nodeComponentId = node.component_node_type_id._id
      const nodeComponentId = node.component_node_type_id
      const componentNodeData = componentNodeTypeDataRef.current

      const targetCompomntNodeItem =
        componentNodeData &&
        componentNodeData.find((item: any) => item.id === nodeComponentId)
      let targettedNodeData: any = []
      if (targetCompomntNodeItem) {
        targettedNodeData = targetCompomntNodeItem.target_component_node_type
      }
      if (targettedNodeData && targettedNodeData.length > 0) {
        NodeTypesDetailsDataForNodeMenu.forEach((item: any) => {
          const check = targettedNodeData.find(
            (target: any) => target._id === item.id,
          )
          if (check) {
            contextMenuInstance.showMenuItem(item.id)
          } else {
            contextMenuInstance.hideMenuItem(item.id)
          }
        })
      } else {
        NodeTypesDetailsDataForNodeMenu.forEach((item: any) => {
          contextMenuInstance.hideMenuItem(item.id)
        })
      }
    },
    [NodeTypesDetailsDataForNodeMenu, contextMenuInstance],
  )

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false)
    setOpenDeleteDialog(false)
  }, [openDialog, openDeleteDialog])

  const handleCloseOutputTypeDialog = useCallback(() => {
    setOutputTypeDialog({ isOpen: false, data: { item: null, event: null } })
  }, [])

  const handleCloseVisualizationTemplateDialog = useCallback(() => {
    setVisualizationTemplatesDialog({
      isOpen: false,
      component_node_type_id: '',
    })
    setTransformationTemplatesDialog({
      isOpen: false,
      component_node_type_id: '',
    })

    setModelPreparationBlockTemplatesDialog({
      isOpen: false,
      component_node_type_id: '',
    })
  }, [])

  const getNodeIcon = useCallback((id: string) => {
    const nodeIconItem = NodeTypesDetailsData.find(
      (item: any) => item.id === id,
    )
    return nodeIconItem ? nodeIconItem.icon : SourceIcon
  }, [])

  useEffect(() => {
    if (cyInstance) {
      cyInstance.nodeHtmlLabel([
        {
          query: 'node', // cytoscape query selector
          halign: 'center', // title vertical position. Can be 'left',''center, 'right'
          valign: 'center', // title vertical position. Can be 'top',''center, 'bottom'
          halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
          valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
          cssClass: '', // any classes will be as attribute of <div> container for every title
          tpl(data: any) {
            return renderToString(
              <Box
                style={{
                  width: '200px',
                  height: '80px',
                  display: 'flex',
                }}
              >
                <Box className={classes.nodeIconStyle}>
                  {
                    <Icon
                      component={getNodeIcon(data.component_node_type_id)}
                    ></Icon>
                  }
                </Box>
                <Box className={classes.nodeTextStyle}>
                  <Box style={{ fontSize: '12px', wordWrap: 'break-word' }}>
                    {/* {data.component_node_type_id.componentNodeType} */}
                  </Box>
                  <Box style={{ fontSize: '14px', wordWrap: 'break-word' }}>
                    {data.component_node_name}
                  </Box>
                </Box>
                {/* <Box
                  className="more-vert-icon-container" // Add a custom class or id to target this specific element
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    paddingLeft: '8px',
                    paddingTop: '4px',
                    color: '#ccc',
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => handleMoreVertClick(event, data)}
                >
                  <MoreVertIcon />
                </Box> */}
              </Box>,
            )
          },
        },
      ])
      const handleMoreVertClick = (event: any, data: any) => {
        event.stopPropagation() // Prevent the outer node click from firing
        setPopoverNodeData(data) // Set the data for the clicked node
        setPopoverAnchorEl(event.currentTarget) // Position the popover
      }
      cyInstance.on('click', 'node', function (evt: any) {
        const node = evt.target
        const nodeData = node.data()

        // Check if MoreVertIcon was clicked
        const moreVertElement = evt.originalEvent.target.closest(
          '.more-vert-icon-container',
        )
        if (moreVertElement) {
          console.log(node.data());
          
          onNodeRightClicked(node.data())
          // handleMoreVertClick(evt.originalEvent, nodeData)
          return // Skip the rest of the click logic
        }

        // Regular node click logic
        const selectedNodeItem = {
          id: nodeData.id,
          component_node_name: nodeData.component_node_name,
          component_node_type_id: nodeData.component_node_type_id,
        }
        nodeDrawerRef &&
          nodeDrawerRef?.current?.setNodeName(nodeData.component_node_name)
        setSelectedNode(selectedNodeItem)
        nodeDrawerRef && nodeDrawerRef?.current?.setDrawerConfiguration([])
      })

      // Attach 'cxttap' event listener
      cyInstance.on('cxttap', 'node', function (event: any) {
        if (projectRole && projectRole?.edit_nodes) {
          const node = event.target
          onNodeRightClicked(node.data())
        }
      })
    }
  }, [
    classes,
    cyInstance,
    options,
    contextMenuInstance,
    onNodeRightClicked,
    getNodeIcon,
    projectRole,
  ])

  const transformGraphData = useMemo(() => {
    const transformedGraphData: any = {
      nodes: [],
      edges: [],
    }
    if (componentDetailsData) {
      const pipelineDataConfiguration = componentDetailsData.configuration
      transformedGraphData.nodes = pipelineDataConfiguration.input.nodes
      transformedGraphData.edges = pipelineDataConfiguration.input.edges
    }
    return transformedGraphData
  }, [componentDetailsData])

  useEffect(() => {
    if (graphRef.current) {
      initCy(graphRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCyInstance(cy)
  }, [cy])

  const triggerLayout = useCallback(() => {
    if (cyInstance) {
      cyInstance.layout(dagreLayout).run()
    }
  }, [cyInstance])

  useEffect(() => {
    if (cyInstance) {
      const finalData = [
        ...transformGraphData.nodes,
        ...transformGraphData.edges,
      ]
      cyInstance.elements().remove()
      cyInstance.add(finalData)
      try {
        cyInstance.layout(dagreLayout).run()
        triggerLayout()
      } catch (error: any) {
        console.log('error', error)
      }
    }
  }, [cyInstance, transformGraphData, triggerLayout])

  const dataPipelineItem = () => {
    return (
      <Box>
        <Box className={classes.cy} ref={graphRef}>
          <GraphControl
            addNewSourceNode={addNewSourceNode}
            cy={cyInstance}
            projectRole={projectRole}
          />
        </Box>
      </Box>
    )
  }

  const heading = useCallback(() => {
    if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
      return `API COMPONENT - ${componentDetailsData?.component_name}`
    } else if (componentDetailsData?.component_type === 'DATA_PIPELINE') {
      return `DATA PIPELINE - ${componentDetailsData?.component_name}`
    } else if (componentDetailsData?.component_type === 'DATA_VISUALIZATION') {
      return `DATA VISUALIZATION - ${componentDetailsData?.component_name}`
    } else if (componentDetailsData?.component_type === 'DOCUMENT_PROCESSING') {
      return `DOCUMENT PROCESSING - ${componentDetailsData?.component_name}`
    } else {
      return componentDetailsData?.component_name
        ? componentDetailsData?.component_name
        : ''
    }
  }, [componentDetailsData])

  const handleShowComponentListDialog = useCallback(async () => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const handlePublishComponent = useCallback(
    async (id: any) => {
      try {
        setLoading(true)
        await ApiServices.publishApp({
          id: componentId,
          projectId: projectId,
          appId: id,
        })

        if (
          componentTypesForJsonCon.includes(
            componentDetailsData?.component_type,
          )
        ) {
          await ApiServices.getJsonConversion({
            componentId: componentId,
            projectId: projectId,
          })
        }

        setLoading(false)
        handleShowAlert('App Successfully Published')
      } catch (error: any) {
        console.log('error', error)
        setLoading(false)
      }
    },
    [componentId, componentDetailsData, handleShowAlert],
  )

  const handleSelectOutputTemplate = useCallback(
    (id: any) => {
      const node = outputTypeDialog.data.event
      const component_node_type_id = node.data('component_node_type_id')
      const nodeType = component_node_type_id
      setNodePropertiesDrawer({
        nodeType,
        show: true,
        isEdit: false,
        nodeAttached: {
          sourceId: node.data('id'),
        },
      })
      setSelectedNode({
        id: null,
        component_node_name: outputTypeDialog.data.item.componentNodeTypeName,
        component_node_type_id: outputTypeDialog.data.item.id,
      })
      nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
      nodeDrawerRef &&
        nodeDrawerRef?.current?.getComponentNodeTemplateConfiguration({
          // nodeTypeId: outputTypeDialog.data.item.id,
          nodeTypeId: id,
          originNodeDetail: {
            id: node.data('id'),
            component_node_name: node.data('component_node_name'),
            newNodeTypeName: outputTypeDialog.data.item.componentNodeTypeName,
          },
        })
    },
    [outputTypeDialog, nodeDrawerRef, setNodePropertiesDrawer, setSelectedNode],
  )
  const handleSelectVisualizationTemplate = useCallback(
    (id: any) => {
      setSelectedNode({
        id: null,
        component_node_name: NODE_TYPES.source.componentNodeTypeName,
        component_node_type_id: NODE_TYPES.source.id,
      })
      nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
      setNodePropertiesDrawer({
        nodeType: NODE_TYPES.source.componentNodeType,
        show: true,
        isEdit: false,
      })
      nodeDrawerRef &&
        nodeDrawerRef?.current?.getComponentNodeTemplateConfiguration({
          nodeTypeId: id,
        })
    },
    [NODE_TYPES],
  )

  const handleSelectTransformationTemplate = useCallback(
    (id: any) => {
      const node = transformationTemplatesDialog.clickedNode
      if (node) {
        const component_node_type_id = node.data('component_node_type_id')
        const nodeType = component_node_type_id
        setNodePropertiesDrawer({
          nodeType,
          show: true,
          isEdit: false,
          nodeAttached: {
            sourceId: node.data('id'),
          },
        })
        setSelectedNode({
          id: null,
          component_node_name: NODE_TYPES.transformation.componentNodeTypeName,
          component_node_type_id: NODE_TYPES.transformation.id,
        })
        nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
        nodeDrawerRef &&
          nodeDrawerRef?.current?.getComponentNodeTemplateConfiguration({
            nodeTypeId: id,
            originNodeDetail: {
              id: node.data('id'),
              component_node_name: node.data('component_node_name'),
              newNodeTypeName: NODE_TYPES.transformation.componentNodeTypeName,
            },
          })
      }
    },
    [NODE_TYPES, transformationTemplatesDialog],
  )

  const handleSelectModelPreparationBlockTemplate = useCallback(
    (id: any) => {
      const node = modelPreparationBlockTemplatesDialog.clickedNode

      if (node) {
        const component_node_type_id = node.data('component_node_type_id')
        const nodeType = component_node_type_id
        setNodePropertiesDrawer({
          nodeType,
          show: true,
          isEdit: false,
          nodeAttached: {
            sourceId: node.data('id'),
          },
        })
        setSelectedNode({
          id: null,
          component_node_name: NODE_TYPES.source.componentNodeTypeName,
          component_node_type_id:
            modelPreparationBlockTemplatesDialog.component_node_type_id,
        })
        nodeDrawerRef && nodeDrawerRef?.current?.setNodeName('')
        nodeDrawerRef &&
          nodeDrawerRef?.current?.getComponentNodeTemplateConfiguration({
            nodeTypeId: id,
            originNodeDetail: {
              id: node.data('id'),
              component_node_name: node.data('component_node_name'),
              newNodeTypeName: NODE_TYPES.transformation.componentNodeTypeName,
            },
          })
      }
    },
    [NODE_TYPES, modelPreparationBlockTemplatesDialog],
  )

  const handleChangeComponentName = useCallback(
    async (componentName: string) => {
      try {
        const details = JSON.parse(JSON.stringify(componentDetailsData))
        details.component_name = componentName
        details.id = componentId
        const param = {
          data: details,
        }
        setLoading(true)
        const resp: any = await ApiServices.changeComponentName(param)
        setIsNameChange(!isNameChange)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [componentDetailsData, componentId],
  )

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            // background: '#fff',
            alignItems: 'center',
            paddingBottom: '5px',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                // flex: 1,
                // justifyContent: 'start',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>{heading()}</Typography>
              {projectRole && projectRole?.edit_name && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenChangeComponentNameDialog(true)}
                >
                  <DriveFileRenameOutlineIcon fontSize="small" />
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end', pr: 2 }}>
            <Button
              sx={{ height: '32px' }}
              variant="contained"
              startIcon={<Check />}
              onClick={() => handleShowComponentListDialog()}
              disabled={projectRole && !projectRole?.publish}
            >
              Publish
            </Button>
          </Box>
        </Box>
        {/* {drawer()} */}
        {dataPipelineItem()}
      </Box>
      <>
        <NodeDetailsDrawer
          ref={nodeDrawerRef}
          showNodePropertiesDrawer={showNodePropertiesDrawer}
          setLoading={setLoading}
          setNodePropertiesDrawer={setNodePropertiesDrawer}
          getProjectDetails={getProjectDetails}
          projectId={projectId}
          componentId={componentId}
          selectedNode={selectedNode}
          componentDetailsData={componentDetailsData}
          NODE_TYPES={NODE_TYPES}
          projectRole={projectRole}
        />
      </>
      <Dialog open={openDialog} handleClose={handleCloseDialog} maxWidth="xl">
        <ComponentList
          onClose={handleCloseDialog}
          publishComponent={handlePublishComponent}
        />
      </Dialog>
      <Dialog
        open={outputTypeDialog.isOpen}
        handleClose={handleCloseOutputTypeDialog}
        maxWidth="xl"
      >
        {outputTypeDialog.isOpen && (
          <OutputTemplateList
            onClose={handleCloseOutputTypeDialog}
            selectOutputTemplate={handleSelectOutputTemplate}
            nodeTypeId={outputTypeDialog.data.item.id}
          />
        )}
      </Dialog>
      <Dialog
        open={visualizationTemplatesDialog.isOpen}
        handleClose={handleCloseVisualizationTemplateDialog}
        maxWidth="xl"
      >
        {visualizationTemplatesDialog.isOpen && (
          <OutputTemplateList
            onClose={handleCloseVisualizationTemplateDialog}
            selectOutputTemplate={handleSelectVisualizationTemplate}
            nodeTypeId={visualizationTemplatesDialog.component_node_type_id}
          />
        )}
      </Dialog>
      <Dialog
        open={transformationTemplatesDialog.isOpen}
        handleClose={handleCloseVisualizationTemplateDialog}
        maxWidth="xl"
      >
        {transformationTemplatesDialog.isOpen && (
          <OutputTemplateList
            onClose={handleCloseVisualizationTemplateDialog}
            selectOutputTemplate={handleSelectTransformationTemplate}
            nodeTypeId={transformationTemplatesDialog.component_node_type_id}
            title="Select Transformation Template"
          />
        )}
      </Dialog>
      <Dialog
        open={modelPreparationBlockTemplatesDialog.isOpen}
        handleClose={handleCloseVisualizationTemplateDialog}
        maxWidth="xl"
      >
        {modelPreparationBlockTemplatesDialog.isOpen && (
          <OutputTemplateList
            onClose={handleCloseVisualizationTemplateDialog}
            selectOutputTemplate={handleSelectModelPreparationBlockTemplate}
            nodeTypeId={
              modelPreparationBlockTemplatesDialog.component_node_type_id
            }
            title="Select Model Preparation Block Template"
          />
        )}
      </Dialog>
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
      />
      <ChangeComponentName
        open={openChangeComponentNameDialog}
        setOpen={setOpenChangeComponentNameDialog}
        changeComponentName={({ project_name }: any) =>
          handleChangeComponentName(project_name)
        }
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        handleYesButton={() => {
          setNodeDeleteFlag(true)
        }}
        handleClose={handleCloseDialog}
        dialogHeading="Confirmation"
        dialogContentText="Do You Really Want to Delete This Component"
      />
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options?.menuItems?.map((menuItem: any) => (
          <MenuItem
            key={menuItem.id}
            onClick={(event: any) => {
              menuItem.onClickFunction(event) // Call the respective function on click
              handlePopoverClose() // Close the popover after the action
            }}
          >
            {menuItem.content}
          </MenuItem>
        ))}
      </Popover>
      <LoaderComponent open={isLoading} />
    </>
  )
}

export default DataPipeline
