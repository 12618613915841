/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import { blue } from '@mui/material/colors'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { loginType } from 'types/request'
import { ApiServices, loginService } from 'api/services'
import { decryptObject, encryptObject } from 'utility/enc-dec'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  removeProfileInfo,
  removeToken,
  setProfileInfo,
  setToken,
} from 'api/apiUtility'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
import { useDispatch } from 'react-redux'
import { setProfileInformation } from 'store/profileInfoSlice'

interface IFormInputs {
  username: string
  password: string
}

const schema = yup.object().shape({
  username: yup.string().email().required('Username is required'),
  password: yup.string().required('Password is required'),
})

const messages = {
  success: 'User logged in successfully',
  error: 'Username or password is incorrect',
}

const SignIn = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInputs>({
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(schema),
  })
  const dispatch = useDispatch()
  // const { changePage } = props
  const navigate = useNavigate()
  const [isLoading, setLoadingState] = useState(false)
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 2000,
    message: '',
  })

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    async (data) => {
      try {
        const obj: IFormInputs = {
          username: data.username,
          password: data.password,
        }
        const encryptedObject = encryptObject(obj)
        const payload: loginType = {
          data: '',
        }
        if (encryptedObject && encryptedObject.data) {
          payload.data = encryptedObject.data
        } else {
          return
        }
        setLoadingState(true)
        const result = await loginService.apiLogin(payload)
        setAlertConfig((prev) => {
          return {
            ...prev,
            type: 'success',
            message: messages.success,
            isOpen: true,
          }
        })
        setLoadingState(false)

        try {
          const decryptedResult = decryptObject(result.data.data)
          if (decryptedResult.data) {
            const parsedDecrypedData = JSON.parse(decryptedResult.data)
            setToken(parsedDecrypedData.token)
            ApiServices.getProfileInfoFromAPI()
              .then((res: any) => {
                setProfileInfo(res.data)

                dispatch(setProfileInformation(res.data))
                if (!res?.data?.isSystemUser) {
                  navigate(`/${PAGE_ROUTE_CONSTANTS.HOME}`)
                } else {
                  navigate(`/${PAGE_ROUTE_CONSTANTS.SUPERADMINDASHBOARD}`)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          }
        } catch (error: any) {
          console.log('error', error)
        }
      } catch (error: any) {
        setLoadingState(false)
        setAlertConfig((prev) => {
          return {
            ...prev,
            type: 'error',
            message: messages.error,
            isOpen: true,
          }
        })
        console.log('error', error)
      }
    },
    [navigate],
  )

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ background: '#FFF' }}
      >
        <>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#6B778C',
              marginBottom: '10px',
            }}
            gutterBottom
          >
            Username
          </Typography>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Username"
                fullWidth
                margin="normal"
                error={!!errors.username}
                helperText={errors.username?.message}
                sx={{ m: 0, marginBottom: '20px' }}
                size="small"
              />
            )}
          />
        </>
        <>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#6B778C',
              marginBottom: '10px',
            }}
            gutterBottom
          >
            Password
          </Typography>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                error={!!errors.password}
                helperText={errors.password?.message}
                sx={{ m: 0, marginBottom: '20px' }}
                size="small"
              />
            )}
          />
        </>
        <FormControlLabel
          control={<Checkbox size="small" />}
          label="Remember me"
          sx={{
            fontSize: '12px !important',
            color: '#091E42',
            marginTop: '10px',
            '& .MuiCheckbox-root': {
              padding: '4px', // Adjust the size of the checkbox
            },
            '& .MuiFormControlLabel-label': {
              fontSize: '12px', // Add some space between the checkbox and label
            },
          }}
        />
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </Box>
      </Box>

      <>
        <Typography
          gutterBottom
          sx={{ color: '#0052CC', fontSize: '14px', cursor: 'pointer' }}
        >
          Forgot Your Password?
        </Typography>
      </>
      {/* <>
        <Typography variant="subtitle1" gutterBottom>
          Don’t have an account?
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => {
              changePage(SIGN_UP)
            }}
          >
            SignUp
          </Button>
        </Typography>
      </> */}
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  )
}

export default SignIn
