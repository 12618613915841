import React, { forwardRef, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import AllComponents from './pipeline-components/AllComponents'
import StreamingDataPipeline from './pipeline-components/streamingDataPipeline/StreamingDataPipeline'
import DataPipelineComponent from './pipeline-components/dataPipeline/DataPipelineComponent'
import TrainingDataPipeline from './pipeline-components/modelTrainingDataPipeline/TrainingDataPipeline'
import DocumentProcessingPipeline from './pipeline-components/documentProcessingDataPipeline/DocumentProcessingPipeline'
import KnowledgeRetrievalPipeline from './pipeline-components/knowledgeRetrivalDataPipeline/KnowledgeRetrievalPipeline'
import DataAccessComponent from './pipeline-components/dataAccessPipeline/DataAccessComponent'
import VisualizationDataPipeline from './pipeline-components/VisualizationDataPipeline/VisualizationDataPipeline'

const NodeDetailsDrawer = forwardRef((props: any, ref) => {
  const [searchParams] = useSearchParams()
  const [componentType, setComponentType] = useState<any>('')
  useEffect(() => {
    const compType = searchParams.get('componentType')
    compType && setComponentType(compType)
  }, [])
  if (componentType === 'STREAMING_DATAFLOW') {
    return <StreamingDataPipeline {...props} ref={ref} />
  } else if (componentType === 'DATA_PIPELINE') {
    return <DataPipelineComponent {...props} ref={ref} />
  } else if (componentType === 'MODEL_TRAINING') {
    return <TrainingDataPipeline {...props} ref={ref} />
  } else if (componentType === 'DOCUMENT_PROCESSING') {
    return <DocumentProcessingPipeline {...props} ref={ref} />
  } else if (componentType === 'KNOWLEDGE_RETRIEVAL') {
    return <KnowledgeRetrievalPipeline {...props} ref={ref} />
  } else if (componentType === 'DATA_ACCESS') {
    return <DataAccessComponent {...props} ref={ref} />
  } else if (componentType === 'DATA_VISUALIZATION') {
    return <VisualizationDataPipeline {...props} ref={ref} />
  } else {
    return <AllComponents {...props} ref={ref} />
  }
})

NodeDetailsDrawer.displayName = 'NodeDetailsDrawer'

export default NodeDetailsDrawer
