/* eslint-disable no-unused-vars */
import useCytoscapeHook from 'hooks/useCytoscape'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { styles } from '../graph-component/styles'
import { style } from './stylesheet'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import useClasses from 'hooks/styleHook'
import { EulerLayout } from 'utility/cytoscape-layout'
import { ApiServices } from 'api/services'
import AppGraphControl from '../graph-component/app-graph-control'
import AppKnowledgeFilter from 'pages/apps/knowledge-graph/app-filter'
import { INFINITY_X_BREADCRUMBS } from 'utility/constants'
import { NodeTypesAndColorsForKnowledgeGraph } from 'pages/apps/apps-utility/utility'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'
import DrawerComponent from 'components/atoms/drawer'

const AppKnowledgeGraphVisualization = (props: any) => {
  const { setLoading, projectId } = props
  const { cy, initCy } = useCytoscapeHook({ stylesheet: style })
  const tooltipRef = useRef(null)
  const classes = useClasses(styles)
  const [cyInstance, setCyInstance] = useState<any>()
  const [edgesTypesColors, setEdgesTypesColors] = useState<any>([])
  const navigate = useNavigate()
  const [chartComponentData, setChartComponentData] = useState<any>()
  const [formData, setFormData] = useState<Record<string, string>>({})

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    // getAppsDGraphNetworkData(formData)
    // Submit logic here
  }
  const graphRef = useRef()
  const [networkData, setNetworkData] = useState({
    nodes: [],
    edges: [],
  })
  const [renderLayput, setRenderLayout] = useState(false)

  useEffect(() => {
    const ele = document.getElementById(INFINITY_X_BREADCRUMBS)
    if (ele) {
      ele.style.marginLeft = '300px'
    }
    return () => {
      if (ele) {
        ele.style.marginLeft = '0px'
      }
    }
  }, [])

  useEffect(() => {
    if (graphRef.current) {
      initCy(graphRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setCyInstance(cy)
  }, [cy])

  /** Node Mouseover and mouseout */
  useEffect(() => {
    if (cyInstance) {
      cyInstance.on('mouseover', 'node', function (event: any) {
        const node = event.target
        const tooltip: any = tooltipRef.current
        tooltip.textContent = node.data('name')
        tooltip.style.display = 'block'
        tooltip.style.left = event.originalEvent.pageX - 20 + 'px'
        tooltip.style.top = event.originalEvent.offsetY + 'px'
      })

      cyInstance.on('mouseout', 'node', function (event: any) {
        const tooltip: any = tooltipRef.current
        tooltip.style.display = 'none'
      })
    }
  }, [cyInstance])

  const getLocationOrDefendant = useCallback(
    async (location: string, defendant: string) => {
      try {
        setLoading(true)
        const resp = await ApiServices.getLocationOrDefendant({
          location,
          defendant,
        })
        setLoading(false)
        setNetworkData({
          nodes: resp.data.data.nodes,
          edges: resp.data.data.edges,
        })
        const edgeTypes = resp.data.data.edgeTypes
        const edgeColorData: any = NodeTypesAndColorsForKnowledgeGraph.filter(
          (item: Record<string, string>) => edgeTypes.includes(item.type),
        )
        setEdgesTypesColors(edgeColorData)
      } catch (error: any) {
        setEdgesTypesColors([])
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const triggerLayout = useCallback(() => {
    if (cyInstance) {
      cyInstance.layout(EulerLayout).run()
      setRenderLayout(false)
    }
  }, [cyInstance])

  useEffect(() => {
    if (networkData.nodes.length > 0) {
      setRenderLayout(true)
    }
  }, [networkData])

  useEffect(() => {
    if (cyInstance && renderLayput) {
      const finalData = [...networkData.nodes, ...networkData.edges]
      cyInstance.elements().remove()
      cyInstance.add(finalData)
      try {
        triggerLayout()
      } catch (error: any) {
        console.log('error', error)
      }
    }
  }, [cyInstance, triggerLayout, networkData, renderLayput])

  const showLegends = useMemo(() => {
    return (
      <Box sx={{ position: 'absolute', right: 0, top: 5, marginRight: '20px' }}>
        {edgesTypesColors.map((item: any) => {
          return (
            <Box key={item.name} sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: item.color,
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              ></span>
              {item.name}
            </Box>
          )
        })}
      </Box>
    )
  }, [edgesTypesColors])
  const callRecentAppsDataApi = useCallback(async (projectId: string) => {
    try {
      setLoading(true)
      const resp = await ApiServices.getDetailsSnapShotData(projectId)
      setLoading(false)
      setChartComponentData(resp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    if (projectId) {
      callRecentAppsDataApi(projectId)
    }
  }, [callRecentAppsDataApi, projectId])
  useEffect(() => {
    // Check if filter_columns exist before processing
    const columns =
      chartComponentData?.configuration?.snapshot[0]?.configuration?.snapshot
        ?.filter_columns

    if (columns) {
      const initialFormData = columns.reduce(
        (acc: Record<string, string>, column: any) => {
          const key = column.alias || column.column_name
          // Set the default value from column.default, if available
          acc[key] = column.default || '' // Default to empty string if no default
          return acc
        },
        {},
      )

      // Set the initial formData
      setFormData(initialFormData)
    }
  }, [chartComponentData])
  return (
    <>
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <Box
          ref={tooltipRef}
          sx={{
            position: 'absolute',
            zIndex: 9999,
            border: '1px solid black',
            padding: '1px 5px',
            borderRadius: '9px',
            // background: 'white',
            display: 'none',
          }}
        ></Box>
        {/* <AppKnowledgeFilter getLocationOrDefendant={getLocationOrDefendant} /> */}
        <DrawerComponent anchor={'left'} open={true} width={'300px'}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Configure Graph
          </Typography>
          <Container sx={{ mt: 5, p: '0 !important' }}>
            <form onSubmit={onSubmit}>
              {chartComponentData?.configuration?.snapshot[0]?.configuration?.snapshot?.filter_columns?.map(
                (column: any, index: any) => (
                  <TextField
                    key={index}
                    fullWidth
                    variant="outlined"
                    label={column.label}
                    name={column.alias || column.column_name}
                    type={column.data_type === 'string' ? 'text' : 'number'}
                    value={
                      formData[column.alias || column.column_name] ||
                      column.default ||
                      ''
                    }
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                  />
                ),
              )}

              <Box
                sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}
              >
                {/* <Button variant="contained">Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  // disabled={mobileNumber ? false : true}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Container>
        </DrawerComponent>
        <Box sx={{ width: '100%' }}>
          <Box className={classes.cy} ref={graphRef} sx={{ zIndex: 9 }}>
            <AppGraphControl cy={cyInstance} />
            {showLegends}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AppKnowledgeGraphVisualization
