import React from 'react'

import { Drawer } from '@mui/material'
import { styled } from '@mui/system'
import { StyledThemeProps } from 'types/props-types'
import { DrawerWidth } from 'utility/constants'

const anchor = 'left'

const StyledDrawer = styled(Drawer)<StyledThemeProps>(({ theme, width }) => ({
  flexShrink: 0,
  width,
  anchor: anchor,
  '& .MuiDrawer-paper': {
    width, // Set your desired default width
    height: '92vh !important',
    marginTop: theme.spacing(8.2),
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    marginLeft: '60px',
    zIndex: 1,
  },
}))

const DrawerComponent = (props: any) => {
  const { children, displayDrawer = true, width = DrawerWidth } = props
  return (
    <>
      <StyledDrawer
        open={displayDrawer}
        width={width}
        {...props}
        variant="persistent"
      >
        {children}
      </StyledDrawer>
    </>
  )
}

export default DrawerComponent
